.toggle-archive {
  margin-top: 2px;
  margin-bottom: 2px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .toggle-archive-label {
    margin-right: 5px;
    align-self: center;
  }
  .bs-switch {
    align-self: center;
  }
}
