.super {
  display: flex;
  min-height: 100vh;

  .super-outlet {
    width: 100%;

    .models-table-wrapper {
      margin: 16px;
      .table {
        background-color: white;
      }
    }
  }

  .nav {
    max-width: 180px;
    background-color: $color-nav-trans;

    a {
      color: $color-nav-trans-text;
    }

    & > li > a:hover {
      background-color: $color-nav-trans-hover;
      color: $color-nav-trans-text-secondary;
    }
    & > li > a:focus {
      background-color: $color-nav-trans-focus;
      color: $color-nav-trans-text;
    }
  }

  #app-version {
    padding: 10px 15px;
    color: #4c4c4c;
    font-size: 0.9em;
  }

  .super-files {
    li {
      display: flex;
      flex-direction: column;
    }
  }

  li.list-group-item img {
    height: 20px;
  }

  .super-user-widget {
    display: flex;

    .profile-image {
      margin-right: 8px;
    }

    .actions {
      flex: 1;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
}
