.models-table-wrapper .table {
  background-color: white;
}

.models-table-wrapper {
  tr.selected-row > td:not(.grouping-cell),
  tr.selected-expand > td:not(.grouping-cell) {
    background: $color-row-selected;
  }
}
