#login,
#password-reset,
#password-update {
  margin: auto;
  padding-top: 200px;
  max-width: 400px;
}

.unauthorized {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.create-user {
  .panel {
    padding: 10px;
  }
}

.signup {
  h1 {
    margin-bottom: 2em;
    color: $color-grey;
  }

  a:hover {
    text-decoration: none;
  }

  i {
    font-size: 4em;
  }
}
