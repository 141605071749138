.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 16px;

  & * {
    margin-right: 4px;
  }

  &:last-child {
    margin-right: 0px;
  }
}

.flex-fill {
  flex: 2;
}
