#admin-bookings {
  .mt-booking-actions {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 4px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .booking-actions {
    position: relative;
    top: 33px;
    width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $color-white-trans;
    padding: $padding-sm;
    border-radius: 4px;
    border: 1px solid white;
  }

  .expand-row {
    td {
      padding: 0px;
      ul {
        margin-bottom: 0px;
        li {
          border-radius: 0px;
        }
      }
    }
  }
}
