.booking-day-list {
  margin-bottom: $margin-xl;

  ul {
    padding: 0px;
    width: 100%;
  }

  .booking-day {
    display: flex;
    flex-direction: row;
    width: 100%;

    .booking-date {
      min-width: 28px;
      margin-right: $margin-lg;

      .booking-date-details {
        display: flex;
        flex-direction: column;
        flex: 0;
        align-items: center;
      }
      .circle-responsive {
        background-color: $color-grey;
        .circle-content {
          color: white;
        }
      }
    }
    .booking-entry {
      display: flex;
      flex: 10;
    }
  }
}
