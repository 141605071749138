.flash-messages {
  position: fixed;
  z-index: 220000;
  list-style-type: none;
  right: 10px;

  @if $betabanner {
    top: 35px;
  }

  @else {
    top: 10px;
  }
}

.flash-messages .flash-message {
  width: 300px;
  min-height: 20px;
  margin-bottom: 10px;

  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, .3);
  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, .3);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, .3);
}

.flash-message {
  border-radius: 3px;

  color: #ffffff;
  font-size: 14px;
  padding: 20px 30px 20px 20px;

  position: relative;

  cursor: pointer;
}

.flash-message .flash-message-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.flash-message .flash-message-title {
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.flash-message.success {
  background-color: #47bc44;
}

.flash-message.alert {
  background: #fcc000;
}

.flash-message.alert a {
  color: #b93042;
}

.flash-message.info {
  background: #5bc0de;
}

.flash-message.error {
  background: #ed1c24;
}
