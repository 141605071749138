// Feature flags
$betabanner: true;

$button-margin: 2px;

$default-border-radius: 4px;
$default-padding: 14px;
$padding-sm: 4px;
$padding-md: 8px;
$default-margin: 8px;
$margin-lg: 16px;
$margin-xl: 32px;
