#availability-grid {
  .availability-header {
    td {
      text-align: center;
      p {
        background-color: rgba(255, 255, 255, 0.8);
        padding-right: 4px;
      }
    }
  }

  li.list-group-item {
    display: flex;
    padding: 0px;

    .slot-indicator {
      width: 0px;
    }
    .slot-value {
      flex: 4;
      padding: 10px 10px;
      text-align: center;
    }
    .slot-indicator.available {
      background-color: #a9fe99;
    }
    .slot-value.available {
      background-color: #a9fe99;
    }
    .slot-indicator.reserved {
      background-color: #fcf8e3;
    }
    .slot-value.reserved {
      background-color: #fcf8e3;
    }
    .slot-indicator.booked {
      background-color: #ff6185; /* overrides availability */
    }
    .slot-value.booked {
      background-color: #ff6185; /* overrides availability */
    }
    .slot-indicator.current {
      /* overrides booked */
      background-color: #93e4ff;
    }
    .slot-value.current {
      /* overrides booked */
      background-color: #93e4ff;
    }
    .slot-indicator.selected {
      width: 8px;
      background-color: #ffe473; /* overrides current */
    }
    .slot-value.selected {
      padding-left: 2px;
    }
    .slot-subtext {
      font-size: 0.8em;
    }
  }
}

#availability-grid ul {
  margin-bottom: 0px;
}

#availability-grid li {
  border-radius: 0px;
}

#availability-grid td {
  padding: 0px 4px 4px 0px;
  border: none;
}

#availability-grid .availability-grid-header > th {
  border-top: none;
}

#availability-header {
  z-index: 100;
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
}

#availability-grid-controls {
  margin-top: 20px;
}

#availability-grid-controls > button {
  width: 10%;
}

#availability-grid-controls > p {
  width: 80%;
  text-align: center;
}
