.profile {
  .edit-profile-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    .profile-image {
      margin-right: 0px;
      max-width: 100px;
      max-height: 100px;
      width: 100px;
      height: 100px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    button {
      margin-top: 8px;
    }
  }

  form {
    margin-top: 24px;

    button {
      margin-left: 8px;
    }
  }
}
