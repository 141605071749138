// Buttons

.btn-primary-outline {
  background-color: transparent;
  color: $btn-primary-border;
}
.btn-success-outline {
  background-color: transparent;
  color: $btn-success-border;
}
.btn-info-outline {
  background-color: transparent;
  color: $btn-info-border;
}
.btn-warning-outline {
  background-color: transparent;
  color: $btn-warning-border;
}
.btn-danger-outline {
  background-color: transparent;
  color: $btn-danger-border;
}

// img-button-panel
.img-button-panel {
  min-height: 180px;
  max-height: 180px;
}

// Circles
.circle-responsive {
  background: #ddd;
  border-radius: 50%;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
}

.circle-content {
  float: left;
  line-height: 1;
  margin-top: -0.5em;
  padding-top: 50%;
  padding-left: 0.1em;
  text-align: center;
  width: 100%;
}

// Containers
.container-header {
  border-bottom: 1px dashed $color-text-light-grey;
  padding-bottom: $default-padding;
  margin-bottom: $default-margin;
}

// Schedule
.schedule-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .schedule-info {
    display: flex;
    flex-direction: column;
    .schedule-details {
      color: black;
    }
    .schedule-time {
      margin-top: 4px;
      color: $color-text-light-grey;
      font-style: italic;
      font-size: 0.8em;
    }
  }
  .schedule-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
