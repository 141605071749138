@import 'ember-bootstrap/bootstrap';
@import 'ember-power-select/themes/bootstrap';
@import 'ember-power-select';

// Global
@import 'variables';
@import 'colors';
@import 'auth';
@import 'layout';
@import 'components';
@import 'roles';

// Components
@import 'flash-messenger';
@import 'nav-component';
@import 'availability-grid';
@import 'toggle-archive';
@import 'breadcrumb';
@import 'load-indicator';
@import 'file-uploader';
@import 'components/booking-month-list';
@import 'components/booking-week-list';
@import 'components/booking-day-list';
@import 'components/booking-entry';
@import 'components/ember-models-table';

// Pages
@import 'admin';
@import 'super';
@import 'profile-image';
@import 'todos';
@import 'profile';
@import 'guardian';
@import 'pages/admin-bookings';
@import 'pages/admin-bookings-edit';

html {
  // TODO: only add the image once the application has loaded, so that we don't
  //       see the background image before the main screen. Handle in application
  //       view on didInsertElement
  height: 100vh;
  background-image: url('/img/background/0.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

body {
  min-height: 100vh;

  // Filter over background image
  background-color: rgba(255, 255, 255, 0.1);

  // Landing Page
  #index-container {
    min-height: 100vh;
    background-color: #f8f8f8;
    background-image: url(../img/banner.jpg);
    background-size: cover;
    background-position: center;
  }

  // Default padding for containers
  .container {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/** Landing Page **/
#banner img {
  min-width: 100%;
}

#banner {
  position: relative;
}

#banner h4 {
  font-weight: normal;
}

#banner-overlay {
  position: absolute;
  width: 40%;
  right: 0px;
  top: 40%;
  padding: 40px;
  z-index: 200;
  color: white;
  /* Fall-back for browsers that don't support rgba */
  background: grey;
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  #banner-overlay {
    width: 100%;
    height: 100%;
    top: 0%;
  }

  #banner-overlay-text {
    display: none;
    /* Hide text so button stays in banner */
  }

  #banner-overlay .btn {
    margin-top: 20%;
  }
}

@media (min-width: 768px) {
  #banner-overlay {
    width: 100%;
    height: 100%;
    top: 0%;
  }
}

@media (min-width: 992px) {
  #banner-overlay {
    height: auto;
    width: 40%;
    top: 35%;
  }
}

/** Nav bar **/
.navbar {
  margin-bottom: 0px;

  .navbar-right {
    padding-right: 0px;
  }
}

.navbar-controls {
  display: flex;
  align-items: center;
  padding-top: 10px;

  .profile-container {
    padding: 0px !important;
    margin-right: 5px;
    .profile-image {
      width: 30px;
      height: 30px;
    }
  }

  .btn {
    margin-left: 5px;
  }
}

/* Pics in lists */
li.list-group-item img {
  display: inline;
  height: 50px; // scss says ignored but it does constrain images
}

li.list-group-item.file-preview img {
  height: auto;
}

a.list-group-item img {
  display: inline;
  height: 50px; // scss says ignored but it does constrain images
}

/** Margins **/
.buffer-bottom {
  margin-bottom: 15px;
}
.buffer-top {
  margin-top: 15px;
}

/** Button Layout **/
.button-row > a {
  margin-left: $button-margin;
}
.button-row > button {
  margin-left: $button-margin;
}

/** Doorbell **/
#doorbell-background {
  background-color: rgb(0, 0, 0);
}

#doorbell {
  #doorbell-container {
    #doorbell-form {
      box-shadow: none;
    }
  }
}
