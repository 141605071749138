.admin {
  display: flex;
  min-height: 100vh;

  .nav {
    max-width: 180px;
    background-color: $color-nav-trans;

    a {
      color: $color-nav-trans-text;
    }

    & > li > a:hover {
      background-color: $color-nav-trans-hover;
      color: $color-nav-trans-text-secondary;
    }
    & > li > a:focus {
      background-color: $color-nav-trans-focus;
      color: $color-nav-trans-text;
    }
  }

  #app-version {
    padding: 10px 15px;
    color: $color-text-light-grey;
    font-size: 0.9em;
  }
}

.students {
  .power-select {
    div:first-of-type {
      margin: 0px;
      width: 100%;
    }
  }
}

.registrations {
  .x-toggle-component {
    justify-content: left;
    .off-label {
      display: none;
    }
    .on-label {
      padding-left: 1.5rem;
      font-weight: inherit;
    }
  }
}
