$color-grey: #555;
$color-grey-light: #e7e7e7;
$color-grey-trans: rgba(85, 85, 85, 0.66);
$color-grey-trans-light: rgba(152, 152, 152, 0.66);
$color-white-trans: rgba(255, 255, 255, 0.73);
$color-drop-zone: rgba(45, 137, 239, 0.13);
$color-drop-zone-hover: rgba(22, 170, 113, 0.19);

// Admin
$color-nav-trans-text: rgb(75, 162, 236);
$color-nav-trans-text-secondary: rgb(192, 225, 253);
$color-nav-trans-breadcrumb: rgba(53, 53, 53, 0.8);
$color-nav-trans: rgba(53, 53, 53, 0.7);
$color-nav-trans-focus: rgb(255, 255, 255);
$color-nav-trans-hover: rgba(255, 255, 255, 0);

// Text
$color-text-light-grey: #a2a2a2;
$color-text-dark-grey: #6d6b6b;

// Fill Colors
$color-primary: #a2ceff;

// Role Colors
$color-role-default: rgb(100, 100, 100);
$color-role-default-background: lighten($color-role-default, 50%);
$color-role-default-border: lighten($color-role-default, 25%);
$color-role-student: rgb(0, 81, 173);
$color-role-student-background: lighten($color-role-student, 50%);
$color-role-student-border: lighten($color-role-student, 25%);
$color-role-guide: rgb(0, 107, 0);
$color-role-guide-background: lighten($color-role-guide, 50%);
$color-role-guide-border: lighten($color-role-guide, 25%);
$color-role-guardian: rgb(71, 0, 85);
$color-role-guardian-background: lighten($color-role-guardian, 50%);
$color-role-guardian-border: lighten($color-role-guardian, 25%);

// Bookings
$color-booking-list: #fdf16524;
// Booking Status
$booking-cancelled: #ffa2d8;
$booking-pending: #a2ceff;
$booking-confirmed: #a2ffae;
$booking-attended: #ffe9a2;
$booking-missed: #ffa2a2;
$booking-missed-with-24hrs: #ffb0a2;
$booking-missed-guide: #ffa2db;
$booking-missed-academy: #ffa2a2;

// Selection
$color-row-selected: #edffa7;
