.student-list {
  display: flex;
  align-items: center;
  .info {
    margin-left: 5px;
  }
}

.guardian-student {
  .item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .item-main {
      display: flex;
      align-items: center;
    }
    .item-info {
      font-size: 0.8em;
      color: $color-grey;
      margin-left: 5px;
    }
  }
}
