.booking-week-list {
  background-color: $color-white-trans;
  border-radius: $default-border-radius;

  .week-list-header {
    padding: $default-padding;
    display: flex;
    align-items: center;
    margin-bottom: $margin-lg;
    height: 80px;
    background-color: $color-booking-list;
    border-bottom: 1px solid $color-grey-trans-light;
  }

  .week-list {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: $default-padding;

    .week-dates {
      margin-left: 46px;
      margin-bottom: $default-margin;
      font-size: 1rem;
      color: $color-text-dark-grey;
    }
  }
}
