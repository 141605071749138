.file-uploader {
  .modal-content.modal-body {
    outline: none !important;
  }

  .x-file-input label {
    width: 100%;
    outline: none !important;

    .ember-file-drop-zone {
      height: 46vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-drop-zone;
      border: 4px dashed lightgrey;
      border-radius: 6px;
      font-size: 2rem;
      font-weight: normal;
      // transition: all 1s ease-in-out;
    }

    .ember-file-drop-zone.disabled {
      color: gray;
      background: lightgray;
    }

    .ember-file-drop-zone.dragging:not(.disabled) {
      background: $color-drop-zone-hover;
    }

    .ember-file-drop-zone.hovering:not(.disabled) {
      background: $color-drop-zone-hover;
    }
  }
}
