.role {
  color: $color-role-default;
  background-color: $color-role-default-background;
  border: 1px solid $color-role-default-border;
  border-radius: 4px;
  padding: 4px;
  font-size: 0.8em;

  &.role-student {
    color: $color-role-student;
    background-color: $color-role-student-background;
    border-color: $color-role-student-border;
  }
  &.role-guide {
    color: $color-role-guide;
    background-color: $color-role-guide-background;
    border-color: $color-role-guide-border;
  }
  &.role-guardian {
    color: $color-role-guardian;
    background-color: $color-role-guardian-background;
    border-color: $color-role-guardian-border;
  }
}
