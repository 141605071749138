.nav-component {
  margin-bottom: 5px;

  .nav > li > a {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .nav-pills > li + li {
    padding-left: 2px;
    padding-right: 2px;
  }

  .nav-pills {
    > li {
      // Links rendered as pills
      > a {
        color: $color-grey-trans;
        border: 1px solid rgba(85, 85, 85, 0.37);
        border-radius: $nav-pills-border-radius;
        background-color: rgba(238, 238, 238, 0.34);

        &:hover,
        &:focus {
          background-color: $nav-link-hover-bg;
          color: $color-grey;
        }
      }

      + li {
        margin-left: 2px;
      }

      // Active state
      &.active > a {
        &,
        &:hover,
        &:focus {
          color: $nav-pills-active-link-hover-color;
          background-color: $nav-pills-active-link-hover-bg;
        }
      }
    }
  }
}
