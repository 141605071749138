.todos {
  a.active {
    i {
      color: white;
    }
  }

  .todos-complete {
    a {
      color: $color-grey;
    }
  }

  .todo {
    .list-group-item {
      border: none;
    }
  }
}

.inline-todo {
  background-color: #f5f5f5;
  border-top: none;
  border-bottom: none;
}

.item-actions {
  .btn {
    margin-left: 5px;
  }
}

.item-row {
  display: flex;
  align-items: center;

  .items-left {
    display: flex;
    flex: 1;
    align-items: center;

    .item-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  .items-right {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    .spacer {
      flex: 1;
    }
  }
}
