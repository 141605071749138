.booking-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  border-radius: $default-border-radius;
  padding: $default-padding;
  background-color: $color-primary;

  &.is-cancelled {
    background-color: $booking-cancelled;
  }
  &.is-pending {
    background-color: $booking-pending;
  }
  &.is-confirmed {
    background-color: $booking-confirmed;
  }
  &.is-attended {
    background-color: $booking-attended;
  }
  &.is-missed {
    background-color: $booking-missed;
  }
  &.is-missed-with24-hrs {
    background-color: $booking-missed-with-24hrs;
  }
  &.is-missed-academy {
    background-color: $booking-missed-academy;
  }
  &.is-missed-guide {
    background-color: $booking-missed-guide;
  }

  .booking-details {
    display: flex;
    flex-direction: column;
    flex: 2;

    .booking-with {
      font-weight: bold;
    }

    .booking-cancelled-by {
      color: $color-text-dark-grey;
      font-size: 1.2rem;
    }

    .booking-status {
      font-size: 1.2rem;
      font-style: italic;
      color: $color-text-dark-grey;
    }
  }

  .booking-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
