ol.noBreadCrumbs {
  margin: 0px;
}

.breadcrumb {
  margin-bottom: 0px;
  border-radius: 0px;
  background-color: $color-nav-trans-breadcrumb;

  li {
    color: $color-nav-trans-text-secondary;
  }
  a:hover {
    text-decoration: none;
  }
  a.active {
    color: $color-nav-trans-text;
  }
}
